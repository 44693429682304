/**
 * @file
 * Styles for the front page.
*/

@import "variables";

//
// Front Page Content
//

body.front {
  #content {
    @include zen-grid-item(24,1);
    padding: 10px 0 10px;
  }
}

body.front #content .view-front-page-content {

  // All Views Rows
  .views-row {
    margin-bottom: 10px;
    padding: 0 5px;
    float: left;
    width: 25%;
  }

  // IF 1
  .views-row:first-child:nth-last-child(1) {
    width: 50%;
    margin: 0 auto;
    float: none;
    
    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }
  
  // IF 2 or 5
  // 2
  .views-row:nth-child(1):nth-last-child(2),
  .views-row:nth-child(2):nth-last-child(1) {
    width: 50%;
  }
  // 5
  .views-row:nth-child(1):nth-last-child(5),
  .views-row:nth-child(2):nth-last-child(4) {
    width: 50%;
  }
  .views-row:nth-child(3):nth-last-child(3),
  .views-row:nth-child(4):nth-last-child(2),
  .views-row:nth-child(5):nth-last-child(1) {
    width: 33.333333%;
  }
  
  // If 3 or 6
  .views-row:first-child:nth-last-child(3),
  .views-row:first-child:nth-last-child(3) ~ .views-row,
  .views-row:first-child:nth-last-child(6),
  .views-row:first-child:nth-last-child(6) ~ .views-row {
    width: 33.333333%;
  }
    
  // If 4
  .views-row:first-child:nth-last-child(4),
  .views-row:first-child:nth-last-child(4) ~ .views-row {
    width: 25%;
    
    @media only screen and (max-width: 1024px) {
      width: 50%;
    }
  }
  
  // IF 7
  .views-row:nth-child(1):nth-last-child(7),
  .views-row:nth-child(2):nth-last-child(6),
  .views-row:nth-child(3):nth-last-child(5) {
    width: 33.333333%;
  }
  .views-row:nth-child(4):nth-last-child(4),
  .views-row:nth-child(5):nth-last-child(3),
  .views-row:nth-child(6):nth-last-child(2),
  .views-row:nth-child(7):nth-last-child(1) {
    width: 25%;
    
    @media only screen and (max-width: 1024px) {
      width: 50%;
    }
  }
    
  // IF 8
  .views-row:nth-child(1):nth-last-child(8),
  .views-row:nth-child(2):nth-last-child(7),
  .views-row:nth-child(3):nth-last-child(6),
  .views-row:nth-child(4):nth-last-child(5),
  .views-row:nth-child(5):nth-last-child(4),
  .views-row:nth-child(6):nth-last-child(3),
  .views-row:nth-child(7):nth-last-child(2),
  .views-row:nth-child(8):nth-last-child(1) {
    width: 25%;    
  }
  
  @media only screen and (max-width: 1024px) {
    .views-row:nth-child(1):nth-last-child(8),
    .views-row:nth-child(2):nth-last-child(7) {
      width: 50%;
    }
    .views-row:nth-child(3):nth-last-child(6),
    .views-row:nth-child(4):nth-last-child(5),
    .views-row:nth-child(5):nth-last-child(4),
    .views-row:nth-child(6):nth-last-child(3),
    .views-row:nth-child(7):nth-last-child(2),
    .views-row:nth-child(8):nth-last-child(1) {
      width: 33.333333%;    
    }
  }
  // Layout
  
  /*.views-row-1 {
    @include zen-grid-item(6,1); 
    @media only screen and (max-width: 1150px) {
      @include zen-grid-item(12,1);
    }
    @media only screen and (max-width: 490px) {
      @include zen-grid-item(24,1);
    }   
  }
  
  .views-row-2 {
    @include zen-grid-item(6,7); 
    @media only screen and (max-width: 1150px) {
      @include zen-grid-item(12,13);
    }
    @media only screen and (max-width: 490px) {
      @include zen-grid-item(24,1);
      clear: left;
    }  
  }
  
  .views-row-3 {
    @include zen-grid-item(6,13); 
    @media only screen and (max-width: 1150px) {
      @include zen-grid-item(8,1);
      clear: left;
    }  
    @media only screen and (max-width: 850px) {
      @include zen-grid-item(12,1);
      clear: left;
    }
    @media only screen and (max-width: 490px) {
      @include zen-grid-item(24,1);
      clear: left;
    }
  }
  
  .views-row-4 {
    @include zen-grid-item(6,19); 
    @media only screen and (max-width: 1150px) {
      @include zen-grid-item(8,9);
    }  
    @media only screen and (max-width: 850px) {
      @include zen-grid-item(12,13);
    }
    @media only screen and (max-width: 490px) {
      @include zen-grid-item(12,1);
    }
    @media only screen and (max-width: 490px) {
      @include zen-grid-item(24,1);
      clear: left;
    }
  }
  
  .views-row-5 {
    @include zen-grid-item(6,1);
    clear: left; 
    @media only screen and (max-width: 1150px) {
      @include zen-grid-item(8,17);
      clear: none;
    }
    @media only screen and (max-width: 850px) {
      @include zen-grid-item(12,1);
      clear: left;
    }
    @media only screen and (max-width: 490px) {
      @include zen-grid-item(24,1);
      clear: left;
    }
  }
  
  .views-row-6 {
    @include zen-grid-item(6,7); 
    @media only screen and (max-width: 1150px) {
      @include zen-grid-item(8,1);
      clear: left;
    }
    @media only screen and (max-width: 850px) {
      @include zen-grid-item(12,13);
      clear: none;
    }
    @media only screen and (max-width: 490px) {
      @include zen-grid-item(24,1);
      clear: left;
    }
  } 
  
  .views-row-7 {
    @include zen-grid-item(6,13); 
    @media only screen and (max-width: 1150px) {
      @include zen-grid-item(8,9);
    }
    @media only screen and (max-width: 850px) {
      @include zen-grid-item(12,1);
      clear: left;
    }
    @media only screen and (max-width: 490px) {
      @include zen-grid-item(24,1);
      clear: left;
    }
  }
  
  .views-row-8 {
    @include zen-grid-item(6,19); 
    @media only screen and (max-width: 1150px) {
      @include zen-grid-item(8,17);
    }
    @media only screen and (max-width: 850px) {
      @include zen-grid-item(12,13);
    }
    @media only screen and (max-width: 490px) {
      @include zen-grid-item(24,1);
      clear: left;
    }
  }*/
  
  // Style
  
  .views-field-field-front-page-image {
    margin-bottom: -6px;
    a { line-height: 0; }
    img { 
      margin-bottom: 0; 
      width: 100%;
    }
  }
  
  .views-field-title {
    background: white;
    color: $blue-dark;
    padding: 10px 10px 0;
    
    h2 { 
      margin-top: 0; 
      height: 44px;
      overflow: hidden;
      font-size: 48px;
    }
    
    a {
      color: $blue-dark;
      
      &:hover {
        color: lighten($blue-dark, 15%);
        text-decoration: none;
      }
    }
  }
  
  .views-field-body {
    padding: 0 10px 10px;
    background: white;
    color: $blue-dark;
    
    .field-content {
      height: 20px;
      overflow: hidden;    
    }
  }
}